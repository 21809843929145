@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "theme";
@import '@angular/material/theming';
@import 'ol/ol.css';
@import 'ol-popup/src/ol-popup.css';
@import 'material-design-icons/iconfont/material-icons.css';
@font-face {
    font-family: "titillium-web";
    src: url("/assets/fonts/TitilliumWeb-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}

$mobile-width: 500px;
$mobile-min-width: 501px;

//Material 2 theme
// Active icon color in list nav
mat-list {
  [mat-list-item].active {
    background-color: mat-color($accent);
    color: mat-color($accent, default-contrast)
    mat-icon {

    }
    mat-icon[mat-list-icon] {
      color: mat-color($accent);
    }
  }
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.mat-drawer-content {
  overflow:hidden !important;
}

body {
  margin:0px;
}

.landing-header-bar{
  background-color: black;
}

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important ;
}


table {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black;
}

.home-mat-card {
  height: calc(100vh - 64px);
  padding:0px;
}

.landing-title-bar{
  height:68px;
  border-bottom: 1px solid #d5d4d4;
 }

table th
{
    background-color: #ffffff;
}
table tr:nth-child(odd) {
background-color: #f2f2f2;
}
table tr:nth-child(even) {
background-color: #ffffff;
}

.mat-tooltip.tooltip {
  font-size: 14px;
  border-radius: 10px;
  white-space: pre-line;
}

.mat-grid-tile-content
{
  justify-content: flex-start!important;
}

.mat-icon
{
  overflow: unset !important;
}